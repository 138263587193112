
import { Calendar } from "v-calendar";
import moment from "moment";

import apiTicket from "../services/ticket";
import { openToast } from "../services/toast";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  /*   IonList,
  IonItem, */
  IonLabel,
  IonText,
  IonBadge,
  IonIcon,
  IonFab,
  IonFabButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  onIonViewWillEnter,
} from "@ionic/vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { refresh, briefcase, chevronForward } from "ionicons/icons";

export default {
  name: "Tab3",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    /*     IonList,
    IonItem, */
    IonLabel,
    IonText,
    IonBadge,
    IonIcon,
    IonFab,
    IonFabButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    Calendar,
  },
  setup() {
    const router = useRouter();
    const tickets = ref([]);
    const loading = ref(false);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    const date = ref(new Date());
    const selectedDay = ref(new Date()); // Add state to store selected day

    const refCalendar = ref(null);

    const todayTicket = ref([]);
    const flagTodayTicket = ref(true); //setto a false quando clicco sul calendario e li nascondo

    const openAndClosedTickets = ref(false);

    //qui mi salvo tutti i ticket così poi in tickets (quello usato "veramente") ci riverso dentro solo quelli che mi servono
    //Qui avrà sempre tutti i ticket così in tickets ci riverso solo il filter di allTickets in base allo stato (aperto 1, chiuso 5)
    const allTickets = ref([]);

    //Qui inserisco, ogni volta, i ticket della giornata che ho cliccato così
    // poi li filtro su SelectedDay.attributes in base al valore della checkbox
    const actualTicket = ref([]);

    /**
     * Open free ticket page
     */
    function openFreeTicketPage() {
      router.push(`free-tickets`);
    }

    /**
     * Getting open and closed tickets
     *
     * Dal service devo ricevere TUTTI i ticket a me assegnati
     * Qui li filtro, mostro quelli aperti ed al click carico anche quelli chiusi
     *
     * Vedere se può creare problemi per la visualizzazione dei ticket nel calendario
     */
    /*     function toggleClosedTickets() {
      //console.log(selectedDay.value);
      if (openAndClosedTickets.value) {
        //Devo visualizzare anche quelli chiusi quindi allTickets
        tickets.value = allTickets.value;
        //console.log("visualizzo tutti i ticket");
      } else {
        //devo visualizzare solo quelli aperti quindi filtro allTickets
        tickets.value = allTickets.value.filter(
          (ticket) => ticket.tickets_status === "1"
        );
        //console.log("visualizzo anche solo quelli aperti");
      }
    } */
    function toggleClosedTickets() {
      const todayDate = moment().format("YYYY-MM-DD");
      if (!openAndClosedTickets.value) {
        if (selectedDay.value) {
          actualTicket.value = allTickets.value.filter(
            (ticket) =>
              ticket.tickets_status === "1" &&
              ticket.tickets_assign_date.includes(selectedDay.value["id"])
          );
          //console.log("actualticket solo aperti ", actualTicket.value);
        } else {
          //mostro solo ticket aperti di oggi (caso in cui non ho ancora cliccato su calendario)
          todayTicket.value = allTickets.value.filter(
            (ticket) =>
              ticket.tickets_status === "1" &&
              ticket.tickets_assign_date.includes(todayDate)
          );
          //console.log("ticket di oggi solo aperti ", todayTicket.value);
        }
      } else {
        if (selectedDay.value) {
          actualTicket.value.length = 0;
          selectedDay.value["attributes"].forEach((element) => {
            actualTicket.value.push(element.customData);
          });
          //console.log("actualticket tutti ", actualTicket.value);
        } else {
          //mostro tutti i ticket di oggi (caso in cui non ho ancora cliccato sul calendario)
          todayTicket.value = allTickets.value.filter((ticket) =>
            ticket.tickets_assign_date.includes(todayDate)
          );
          //console.log("tutti ticket di oggi", todayTicket.value);
        }
      }
    }

    /**
     * Convert tickets array into attributes for calendar visualization
     * Assign key derived from original ticket.tickets_id field
     * Assign ticket element to attribute's customData property to access original ticket in template
     * Assign tickets_data_assegnazione as date to use in calendar visualization
     */
    const setAttributes = computed(() => {
      //console.log("mandi");
      return tickets.value.map((ticket) => ({
        key: `ticket.${ticket.tickets_id}`,
        dot: {
          color: "red",
        },
        /* highlight: {
          color: "red",
          fillMode: "solid",
          class: "custom_highlight_date",
          contentClass: "custom_highlight_date",
        }, */
        dates: ticket.tickets_assign_date,
        customData: ticket,
      }));
    });

    /**
     * Set today ticket for visualization on calendar click
     */
    const dayClicked = (day) => {
      //console.log(day.attributes);
      flagTodayTicket.value = false; //hide today ticket load on first time when clicked on Ticket tab
      selectedDay.value = day;
      //Per filtrare i ticket in base alla checkbox
      toggleClosedTickets();
    };

    /**
     * Print name and last name or customer company based on condition
     */
    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }
    /**
     * Print project code and customers address or " - " based on condition
     */
    const riferimentoImpianto = computed(() => {
      return (impianto) => {
        if (impianto.projects_codice && impianto.customers_address) {
          return `${impianto.projects_codice} - ${impianto.customers_address}`;
        } else {
          return " - ";
        }
      };
    });

    /**
     * Set correct background for ticket status
     */
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //aperto
          className = "danger";
        } else if (statusId == 2) {
          //in lavorazione
          className = "secondary";
        } else if (statusId == 3) {
          //preso in carico
          className = "warning";
        } else if (statusId == 5) {
          //chiuso
          className = "success";
        }
        return className;
      };
    });

    /**
     * Remove html tag from ticket description
     */
    function parseHtmlEnteties(str) {
      if (str) {
        const strippedString = str.replace(/(<([^>]+)>)/gi, "");
        return strippedString.replace(
          /&#([0-9]{1,3});/gi,
          function (match, numStr) {
            const num = parseInt(numStr, 10); // read num as normal number
            return String.fromCharCode(num);
          }
        );
      }
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      const dateFormatted = new Date(date);
      return `${dateFormatted.getDate()}/${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getFullYear()}`;
    }

    /**
     * Passing id to retrive detail in Ticket Detail page
     */
    function openDetail(id: string) {
      router.push(`tab1/${id}`);
    }

    /**
     * Get only todays tickets to display when tab 3 is loading
     * Hide when click on calendar
     */
    function getTodayTicket(tickets) {
      tickets.forEach((ticket) => {
        if (moment.isDate(new Date(ticket.tickets_assign_date))) {
          const todayDate = moment(date.value).format("DD/MM/YYYY");
          const ticketDate = moment(ticket.tickets_assign_date).format(
            "DD/MM/YYYY"
          );
          if (ticketDate === todayDate && ticket.tickets_status === "1") {
            todayTicket.value.push(ticket);
          }
        }
      });
      //Per filtrare i ticket in base alla checkbox
      //toggleTodayClosedTickets();
      //toggleClosedTickets();
    }

    /**
     * Get all Tickets opened for this user
     */
    function getTickets() {
      loading.value = true;
      apiTicket
        .getAllTickets(userID)
        .then((response) => {
          //console.log(response);
          allTickets.value = response;
          /*
          AllTickets ha tutti i ticket (aperti e chiusi), lo filtro per avere solo gli aperti e mostrarli in calendario
          al click della checkbox faccio diventare tickets = allTickets così ho anche i chiusi e all'altro click li tolgo
          per avere solo quelli aperti
          */
          /* tickets.value = allTickets.value.filter(
            (ticket) => ticket.tickets_status === "1"
          ); */
          tickets.value = response;
          selectedDay.value = null;
          // MOSTRATE TICKET DI OGGI --> Mi prendo i ticket di oggi e li mostro, al click su un giorno del calendario devo nasconderli e mostrare quelli "veri del calendario"
          todayTicket.value.length = 0;
          if (todayTicket.value.length === 0) {
            getTodayTicket(tickets.value);
          }
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei ticket", "danger");
          //console.error(error);
        })
        .finally(() => {
          loading.value = false;
          flagTodayTicket.value = true;
        });
    }

    onIonViewWillEnter(() => {
      //Refresh to get auto-assigned ticket
      const fromRoute = router.options.history.state.back;
      if (fromRoute === "/tabs/free-tickets") {
        getTickets();
      }
    });

    onMounted(() => {
      getTickets();
    });

    return {
      loading,
      tickets,
      riferimentoCliente,
      riferimentoImpianto,
      dateFormat,
      openDetail,
      statoTicket,
      date,
      setAttributes,
      dayClicked,
      selectedDay,
      parseHtmlEnteties,
      //...
      refCalendar,
      refresh,
      getTickets,
      //flag per ticket di oggi, se true mostro
      flagTodayTicket,
      todayTicket,
      //aggiunte nuove
      briefcase,
      openFreeTicketPage,
      openAndClosedTickets,
      toggleClosedTickets,
      chevronForward,
      //test per fix checkbox e cambio real time ticket
      actualTicket,
    };
  },
};
